import { useEffect, useRef, useState } from 'react';
import UseMenu from '../../../../hooks/menu';
import styled from 'styled-components';
import { Colors } from '../../../../assets/style/global';
import Color from 'color';
import { HexColorInput, HexColorPicker } from 'react-colorful';
import TextField from '../../../global/textfield';
import { AdminTextField } from '../../category/style';

const SectionButtons = styled('div')`
  grid-row: 1;
  grid-column: 4;
  display: grid;
  grid-auto-flow: column;
`;

const ItemButton = styled('button')<{ $state: boolean }>`
  height: min-content;
  color: white;
  background-color: rgba(0, 230, 118, 0);
  font-size: inherit;
  cursor: pointer;
  border-radius: 0.6rem;
  border: none;
  transition: 300ms;
  &:hover {
    background-color: ${(props: any) =>
      props.$state === true
        ? 'rgba(0, 230, 119, 0.1)'
        : 'rgba(230, 0, 0, 0.1)'};
  }
  &:active {
    background-color: ${(props: any) =>
      props.$state === true
        ? 'rgba(0, 230, 119, 0.5)'
        : 'rgba(230, 0, 0, 0.5)'};
  }
  border: 0.2rem solid
    ${(props: any) => (props.$state === true ? Colors.primary : Colors.error)};

  &:disabled {
    background-color: ${(props: any) =>
      props.$state === true
        ? 'rgba(0, 230, 119, 0.5)'
        : 'rgba(230, 0, 0, 0.5)'};
    cursor: not-allowed !important;
  }
`;

const SectionItem = styled('li')<{ $hidden: boolean }>`
  display: grid;
  grid-auto-flow: column;
  column-gap: 1rem;
  grid-template-columns: 1fr auto auto 25rem;
  font-size: clamp(1rem, 1.2vw, 3rem);
  color: ${(props) => (props.$hidden ? 'grey' : 'white')};
  text-transform: uppercase;
  height: min-content;
  @media screen and (max-width: 768px) {
    column-gap: 0.2rem;
    font-size: clamp(0.5rem, 1.2vw, 1rem);
  }
`;

const SectionItemDescription = styled('div')`
  line-height: min-content;
  font-size: clamp(1rem, 0.8vw, 2rem);
  color: #1bff01;
  height: max-content;

  padding-left: 0.5rem;
  font-weight: 400;
  padding-bottom: 0.2rem;

  @media screen and (max-width: 768px) {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    font-size: clamp(0.3rem, 0.8vw, 1rem);
  }
`;

const SectionItemThc = styled('div')`
  padding-right: clamp(0.1rem, 0.6vw, 1.5rem);
  text-align: center;
  grid-column: 2/3;
  grid-row: 1/2;
  @media screen and (max-width: 768px) {
    padding-right: 0.3rem;
  }
`;

const SectionItemPrice = styled('div')`
  padding-right: clamp(0.1rem, 0.8vw, 2rem);
  text-align: center;
  grid-column: 3/4;
  grid-row: 1/2;
  @media screen and (max-width: 768px) {
    padding-right: 0.1rem;
  }
`;

const EditWrapper = styled('div')`
  background-color: rgba(0, 0, 0, 0.19);
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
`;

const EditBody = styled('div')`
  background-color: ${Colors.gray_dark};
  position: fixed;
  z-index: 5;
  top: 50%;
  right: 40%;
  transform: translate(0, -50%);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2rem;
  padding: 2rem;
  display: grid;
  justify-items: center;
`;

const EditTitle = styled('div')`
  width: max-content;
  text-align: center;
  font-size: 4rem;
  font-weight: 700;
  color: white;
  margin-bottom: 2rem;
`;

const EditColorWrapper = styled('div')`
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  user-select: none;
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  align-items: center;
  height: min-content;
  grid-template-columns: min-content 1fr;
  grid-gap: 1rem;
  width: max-content;
`;

const EdtColorButtonWrapper = styled('div')`
  display: grid;
  grid-auto-flow: row;
  align-items: center;
  width: 100%;
`;

const EditColorSwatch = styled('div')`
  padding: 0.5rem;
  border-radius: 0.6rem;
  display: inline-block;
  cursor: pointer;
  display: grid;
  grid-auto-flow: row;
  background-color: rgba(255, 255, 255, 0.233);
  border: 0.2rem solid ${Colors.primary};
`;

const EditColorButton = styled.div.attrs<{ $color: string | undefined }>(
  (props: { $color: string | undefined }) => ({
    style: { backgroundColor: props.$color },
  })
)`
  cursor: pointer;
  height: 1.4rem;
  width: 100%;
  border-radius: 0.2rem;
`;

const EditColorPickerWrapper = styled('div')`
  position: absolute;
  z-index: 4;
  margin-left: 1rem;
  margin-top: 25rem;
`;

const EditButtons = styled('div')`
  display: grid;
  grid-auto-flow: column;
  width: 100%;
  grid-gap: 2rem;
`;

const EditSaveButton = styled('button')`
  margin-top: 2rem;
  justify-self: center;
  color: white;
  background-color: rgba(0, 230, 118, 0);
  font-size: inherit;
  cursor: pointer;
  border-radius: 0.6rem;
  border: none;
  transition: 300ms;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: end;
  grid-gap: 1rem;
  width: 100%;

  &:after {
    justify-self: start;
    content: '\\e902';
    text-align: center;
    font-size: inherit;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    background-color: rgba(0, 230, 119, 0.1);
  }
  &:active {
    background-color: rgba(0, 230, 119, 0.5);
  }
  border: 0.2rem solid ${Colors.primary};

  &:disabled {
    background-color: rgba(0, 230, 119, 0.5);
    cursor: not-allowed !important;
  }
`;

const EditDeleteButton = styled('button')`
  margin-top: 2rem;
  justify-self: center;
  color: white;
  background-color: rgba(0, 230, 118, 0);
  font-size: inherit;
  cursor: pointer;
  border-radius: 0.6rem;
  border: none;
  transition: 300ms;
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-items: end;
  grid-gap: 1rem;
  width: 100%;

  &:after {
    justify-self: start;
    content: '\\e903';
    text-align: center;
    font-size: inherit;
    font-family: 'icomoon' !important;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    padding: 0;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  &:hover {
    background-color: rgba(230, 0, 0, 0.1);
  }
  &:active {
    background-color: rgba(230, 0, 0, 0.5);
  }
  border: 0.2rem solid ${Colors.error};

  &:disabled {
    background-color: rgba(230, 0, 0, 0.5);
    cursor: not-allowed !important;
  }
`;

const HexCustomInput = styled(HexColorInput)<{ $color: string }>`
  font-size: inherit;
  user-select: none;
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  background-color: transparent;
  border-radius: 1rem;
  border: 0.2rem solid ${(props) => props.$color};

  &:active {
    border: 0.2rem solid ${(props) => props.$color};
  }
`;

const HexCustomWrapper = styled('div')`
  display: grid;
  grid-template-columns: min-content 15rem;
  grid-column: 2;
`;

const HexSelectWrapper = styled('div')`
  display: grid;
  grid-template-columns: min-content 1fr;
  grid-column: 2;
  grid-row: 2;
`;

const SectionItemName = styled('div')<{
  $textColor: string | undefined;
  $hidden: boolean;
}>`
  color: ${(props): any => {
    if (props.$textColor) {
      if (props.$hidden) {
        return Color(props.$textColor).darken(0.5).hex();
      } else return props.$textColor;
    } else {
      if (props.$hidden) {
        return Color('#ffffff').darken(0.5).hex();
      } else return '#ffffff';
    }
  }};
  width: 100%;
  grid-row: 1/2;
  grid-column: 1/-1;
`;

function ItemEdit({ item, setEdit }: any) {
  const [name, setName] = useState<string>(item.name);
  const [description, setDescription] = useState<string>(item.description);
  const [color, setColor] = useState<string>(item.color);
  const [editColor, setEditColor] = useState<boolean>(false);
  const [price, setPrice] = useState<number>(item.price);
  const ref = useRef<any>();
  const buttonRef = useRef<any>();

  const [nameError, setNameError] = useState<string>('');
  const [priceError, setPriceError] = useState<string>('');

  const { editTextColor } = UseMenu();

  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      if (
        ref.current &&
        !ref.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        setEditColor(false);
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const saveItem = () => {
    if (color !== item.color) {
      editTextColor({
        item: {
          artikelId: item.artikelId,
          custom: item.custom,
          amount: item.amount,
          name: name,
          price: item.price,
          thcLvl: item.thcLvl,
          group: item.group,
          description: item.description,
          color: color,
        },
      });
    }
    setEdit(false);
  };

  return (
    <EditWrapper>
      <EditBody>
        <EditTitle>Edit {item.name}</EditTitle>
        {item.custom && (
          <>
            <AdminTextField>
              <TextField
                label={'Name'}
                name='Name'
                value={name}
                type='text'
                error={nameError}
                onChange={(e: any) => {
                  setNameError('');
                  setName(e.target.value);
                }}
                validate={true}
              />
            </AdminTextField>
            <AdminTextField>
              <TextField
                label={'Description'}
                name='Description'
                value={description}
                type='text'
                error={''}
                onChange={(e: any) => {
                  setDescription('');
                  setDescription(e.target.value);
                }}
                validate={false}
              />
            </AdminTextField>
            <AdminTextField>
              <TextField
                label={'Price'}
                name='Price'
                value={price}
                type='number'
                error={priceError}
                onChange={(e: any) => {
                  setPriceError('');
                  setPrice(e.target.value);
                }}
                validate={true}
              />
            </AdminTextField>
          </>
        )}

        <EditColorWrapper>
          Color:
          <HexCustomWrapper>
            HexCode:
            <HexCustomInput color={color} onChange={setColor} $color={color} />
          </HexCustomWrapper>
          <HexSelectWrapper>
            Select:
            <EdtColorButtonWrapper>
              <EditColorSwatch
                ref={buttonRef}
                onClick={() => {
                  setEditColor(!editColor);
                }}
              >
                <EditColorButton $color={color} />
              </EditColorSwatch>
              {editColor && (
                <EditColorPickerWrapper ref={ref}>
                  <HexColorPicker color={color} onChange={setColor} />
                </EditColorPickerWrapper>
              )}
            </EdtColorButtonWrapper>
          </HexSelectWrapper>
        </EditColorWrapper>
        <EditButtons>
          <EditSaveButton
            onClick={() => {
              saveItem();
            }}
          >
            Save
          </EditSaveButton>
          <EditDeleteButton onClick={() => setEdit(false)}>
            Exit
          </EditDeleteButton>
        </EditButtons>
      </EditBody>
    </EditWrapper>
  );
}

export default function ItemItem({ item, custom, textColor, thc }: any) {
  const [edit, setEdit] = useState(false);

  const { hideItem } = UseMenu();
  return (
    <>
      {edit && <ItemEdit item={item} setEdit={setEdit} />}
      <SectionItem $hidden={item.hidden}>
        <SectionItemName $hidden={item.hidden} $textColor={item.color}>
          {item.name}
          {item.description && (
            <SectionItemDescription>{item.description}</SectionItemDescription>
          )}
        </SectionItemName>
        {thc ? (
          item.thcLvl ? (
            <SectionItemThc>{item.thcLvl}%</SectionItemThc>
          ) : (
            <SectionItemThc>-</SectionItemThc>
          )
        ) : null}
        <SectionItemPrice>
          {(Math.round(item.price * 100) / 100).toFixed(2)}
        </SectionItemPrice>
        <SectionButtons>
          <ItemButton
            $state={true}
            onClick={() => hideItem({ id: item.id, hidden: !item.hidden })}
          >
            {item.hidden ? 'Show' : 'Hide'}
          </ItemButton>
          {((item.custom && custom) || textColor) && (
            <ItemButton $state={true} onClick={() => setEdit(!edit)}>
              Edit
            </ItemButton>
          )}
          {item.custom && custom && (
            <ItemButton $state={false}>Delete</ItemButton>
          )}
        </SectionButtons>
      </SectionItem>
    </>
  );
}
